import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto w-fit">
      <p className="text-5xl my-12 text-center">-{t('hello')}</p>
      <img src="https://thumbs.gfycat.com/FreshGleamingFulmar-size_restricted.gif"/>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
